import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import queue from '@/api/queue/queue'

//รายการชุดการตรวจ
const getCheckingSetList = async (body) => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checkingset/list',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// ข้อมูลรายการชุดการตรวจ
const getCheckingSetById = async (id) => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'checkingset',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// เพิ่มข้อมูลรายการชุดการตรวจ
const checkingSetAdd = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checkingset',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// แก้ไขข้อมูลรายการชุดการตรวจ
const CheckingSetUpdate = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checkingset',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

// แก้ไขสถานะข้อมูลรายการชุดการตรวจ
const CheckingSetUpdateStatus = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checkingset/status',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//รายการการตรวจในชุด
const getCheckingInSetList = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checkingset/checkinglist',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//เพิ่มข้อมูลการตรวจในชุด
const CheckingInSetAdd = async (body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checkingset/checking',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//แก้ไขข้อมูลการตรวจในชุด
const CheckingInSetUpdate = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checkingset/checking',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//ลบข้อมูลการตรวจในชุด
const CheckingInSetRemove = async (id) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'checkingset/checking',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}

//แก้ไขสถานะข้อมูลการตรวจในชุด
const CheckingInSetUpdateStatus = async (body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checkingset/checkingstatus',
      body: body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('error : ', err)
      return []
    })
  return response
}


export default {
  getCheckingSetList,
  getCheckingSetById,
  checkingSetAdd,
  CheckingSetUpdate,
  CheckingSetUpdateStatus,
  getCheckingInSetList,
  CheckingInSetAdd,
  CheckingInSetUpdate,
  CheckingInSetRemove,
  CheckingInSetUpdateStatus,
}
